
import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'
import Mydatepick from '../../../COmponents/Mydatepick'
import easyinvoice from 'easyinvoice';
import { saveAs } from "file-saver";

import { PencilIcon, TrashIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Mypaginate from '../../../COmponents/Mypaginate'
import { Empty, Spin } from 'antd'
const excelJS = require("exceljs")

const Mainagencyinvoices = () => {

    const token = localStorage.getItem('token')

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const { toggle } = useContext(Authcontext)
    const basicData = useSelector((state) => state.basicReducer)[0]
    const [platformurl, setplatformname] = useState()

    const navigate = useNavigate()

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))

    const plandata = useSelector((state) => state.pricingReducer).filter((val) => val.id == userinfo.planid)[0]
    const TABLE_HEAD = ["AgencyID", "Invoice No.", "Invoice Date", "Amount", "Usage", "Payment", "Print"];


    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState([])
    const [monthrange, setmonthrange] = useState(null)
    const [fetchload, setfetchload] = useState(false)
    const [range, setrange] = useState(null)
    const [search, setsearch] = useState('')


    const handlefetchdata = () => {
        var date = new Date(range)
        var month = date.getMonth() + 1
        month = month < 10 ? '0' + month : month
        var year = date.getFullYear()
        const final = `${month}${year}`

        setsearch(final)
        callforAPI()
        // return final

    }



    const [downloading, setdownloading] = useState({
        id: '',
        loading: false
    })
    // useEffect(() => {
    //     platformurl && callforAPI()
    // }, [platformurl])

    const callforAPI = async () => {
        setloading(true)

        const res = await gpeapi.get(`/getagencyinvoicesadm?month=${range}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data?.TotalUsers)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const genratedate1 = (date) => {
        const d = new Date(date);
        let month = months[d.getMonth()];
        let year = d.getFullYear()
        let datem = d.getDate()
        return `${datem} ${month} ${year}`
    }

    const onEditUser = async (val) => {

        setdownloading({
            id: val.id,
            loading: true
        })

        const callanydate1 = (days, date) => {
            var mysevendays = new Date(date);
            mysevendays.setDate(mysevendays.getDate() + days);
            return mysevendays.toISOString().split('T')[0]
        }

        var strmm = `${val?.txndata?.invoiceinfo?.company_name} ${val?.invoiceid} ${new Date()}`

        var invoicenamemm = strmm?.replace(/\s/g, "_")

        var data = {
            // Customize enables you to provide your own templates
            // Please review the documentation for instructions and examples
            "customize": {
                //  "template": fs.readFileSync('template.html', 'base64') // Must be base64 encoded html 
            },
            "images": {
                // The logo on top of your invoice
                "logo": basicData?.bigicon,
                // The invoice background
                // "background": "https://public.easyinvoice.cloud/img/watermark-draft.jpg"
            },
            // Your own data
            "sender": {
                "company": basicData?.company_name,
                "address": basicData?.address,
                "zip": basicData?.pincode,
                "city": basicData?.city,
                "country": basicData?.country,
                "custom1": `Email: ${basicData?.invoice_email}`,
                "custom2": `GST: ${basicData?.taxid}`,
                "custom3": `Service: SaaS (Software as a Service)`
            },
            // Your recipient
            "client": {
                "company": val?.txndata?.invoiceinfo?.company_name,
                "address": val?.txndata?.invoiceinfo?.address,
                "zip": val?.txndata?.invoiceinfo?.pincode,
                "city": val?.txndata?.invoiceinfo?.city,
                "country": val?.txndata?.invoiceinfo?.country,
                "custom1": `GST: ${val?.txndata?.invoiceinfo?.taxid}`,
                "custom2": `Partner ID - ${val?.userid}`,
                // "custom3": "custom value 3"
            },
            "information": {
                // Invoice number
                "number": val?.invoiceid,
                // Invoice data
                "date": callanydate1(0, val?.createdAt),
                // Invoice due date
                "due-date": callanydate1(0, val?.duedate)
            },
            // The products you would like to see on your invoice
            // Total values are being calculated automatically
            "products": [
                {
                    "quantity": 1,
                    "description": val?.txndata?.txnname,
                    "tax-rate": 18,
                    "price": val?.txndata?.amount
                },

            ],
            // The message you would like to display on the bottom of your invoice
            // "bottom-notice": "Kindly pay your invoice within 15 days.",
            "bottom-notice": "This is system generated print, No need to Sign",

            // Settings to customize your invoice
            "settings": {
                "currency": "INR", // See documentation 'Locales and Currency' for more info. Leave empty for no currency.
                // "locale": "nl-NL", // Defaults to en-US, used for number formatting (See documentation 'Locales and Currency')        
                // "margin-top": 25, // Defaults to '25'
                // "margin-right": 25, // Defaults to '25'
                // "margin-left": 25, // Defaults to '25'
                // "margin-bottom": 25, // Defaults to '25'
                // "format": "A4", // Defaults to A4, options: A3, A4, A5, Legal, Letter, Tabloid
                // "height": "1000px", // allowed units: mm, cm, in, px
                // "width": "500px", // allowed units: mm, cm, in, px
                // "orientation": "landscape", // portrait or landscape, defaults to portrait
            },
            // Translate your invoice to your preferred language
            "translate": {
                // "invoice": "FACTUUR",  // Default to 'INVOICE'
                "number": "Invoice No.", // Defaults to 'Number'
                "date": "Invoice Date", // Default to 'Date'
                // "due-date": "Verloopdatum", // Defaults to 'Due Date'
                // "subtotal": "Subtotaal", // Defaults to 'Subtotal'
                "products": "Monthly Subscription Plan", // Defaults to 'Products'
                // "quantity": "Aantal", // Default to 'Quantity'
                // "price": "Prijs", // Defaults to 'Price'
                // "product-total": "Totaal", // Defaults to 'Total'
                // "total": "Totaal", // Defaults to 'Total'
                "vat": "GST" // Defaults to 'vat'
            },
        };


        const invocedta = await easyinvoice.createInvoice(data, function (result) {
            easyinvoice.download(`${invoicenamemm}.pdf`, result.pdf)
        });

        setdownloading({
            id: val.id,
            loading: false
        })


    }


    const [loaddownxsl, setloaddownxsl] = useState(false)

    const handledownloadxsl = async () => {
        if (statsdata?.length) {

            const newarrdata = []

            const newdata = statsdata?.map((val)=>{
                let tempdata = {
                    amount:val?.amount,
                    igst:((val?.amount * 18) / 100).toFixed(2),
                    date:val?.date?.split('T')[0],
                    userid:val?.userid,
                    invoiceid:val?.invoiceid,
                    gst:val?.txndata?.invoiceinfo?.taxid,
                    company_name:val?.txndata?.invoiceinfo?.company_name,

                }

                newarrdata.push(tempdata)
            })

            const workbook = new excelJS.Workbook();  // Create a new workbook
            const worksheet = workbook.addWorksheet("My Users"); // New Worksheet
            workbook.creator = "test";
            workbook.lastModifiedBy = "test";
            workbook.created = new Date();
            workbook.modified = new Date();
            
            // Column for data in excel. key must match data key
            worksheet.columns = [
                { header: "SR.", key: "useridd", width: 10 },
                { header: "AGENCY ID", key: "userid", width: 10 },
                { header: "INVOICE NO", key: "invoiceid", width: 20 },
                { header: "DATE", key: "date", width: 15 },
                { header: "PARTY", key: "company_name", width: 25 },
                { header: "GST", key: "gst", width: 30 },
                { header: "TAXABLE", key: "amount", width: 10 },
                { header: "IGST", key: "igst", width: 10 },
                { header: "CGST", key: "", width: 10 },
                { header: "SGST", key: "", width: 10 },
            ];
            // Looping through User data
            let counter = 1;
            newarrdata.forEach(async (user) => {
                user.useridd = counter;
                worksheet.addRow(user); // Add data in worksheet
                counter++;
            });
            // Making first line in excel bold
            worksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
            });

            workbook.xlsx.writeBuffer().then(function(buffer) {
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, `GWALAX-${range}.xlsx`);
              });
          

        }

    }
    // console.log(statsdata)

    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
            {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



            <ToastContainer />




            <div className='flex justify-between items-center m-2'>



                <p className='text-2xl font-medium'>MANAGE AGENCY INVOICES</p>

                <div className='flex justify-center items-center'>
                    <Mydatepick value={monthrange} placeholder={'Select Month '} onChange={(e) => {

                        setmonthrange(e.target.value)
                        setrange(`${e.target.value}`)
                    }} />

                    <div>

                        <Button disabled={fetchload} className='ml-1 mr-1' onClick={handlefetchdata} size="sm">{fetchload ? <Spinner /> : 'Fetch'}</Button>
                    </div>

                    {
                        statsdata?.length ?
                            <Button disabled={loaddownxsl} className='ml-1 mr-1' onClick={handledownloadxsl} size="sm">{loaddownxsl ? <Spinner /> : 'Download XSL'}</Button> : null
                    }


                </div>

            </div>


            <div className='p-5 pt-2'>

                <Spin spinning={loading}>

                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70 uppercase"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {!statsdata?.length ? (
                                    <td colSpan={TABLE_HEAD.length}>

                                        <div className='flex justify-center text-center m-2'>
                                            <Empty />
                                        </div>
                                    </td>
                                ) : statsdata?.map(
                                    (val, index) => {
                                        const isLast = index === statsdata?.docs?.length - 1;
                                        const classes = isLast ? "p-4 w-2" : "p-4 w-2  border-b border-blue-gray-50";

                                        return (
                                            <tr key={index}>

                                                <td className={classes}>
                                                    <Typography variant="small" color="black" className="font-normal">
                                                        {val?.userid}


                                                    </Typography>
                                                </td>

                                                <td className={classes}>
                                                    <Typography variant="small" color="black" className="font-normal">
                                                        {val?.invoiceid}


                                                    </Typography>
                                                </td>

                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal  ">
                                                        {genratedate1(val?.date)}
                                                    </Typography>
                                                </td>


                                                <td className={classes}>
                                                    <Typography variant="small" color="black" className="font-medium ">
                                                        ₹{val?.txndata?.amount}

                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        {genratedate1(val?.fromdate)} to {genratedate1(val?.enddate)}

                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography variant="small" color="blue-gray" className="font-normal">
                                                        Completed

                                                    </Typography>
                                                </td>




                                                <td className={classes}>
                                                    <Button onClick={() => onEditUser(val)} className='shadow-none' size="sm" style={{ backgroundColor: COLORS.primary }}> {downloading.id == val?.id && downloading.loading ? <Spinner /> : 'DOWNLOAD'}</Button>
                                                </td>


                                            </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>

                </Spin>



            </div>





        </div>
    )
}

export default Mainagencyinvoices