
import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Mybtn from '../../../COmponents/Mybtn'
import Mymodal from '../../../COmponents/Mymodal'
import { COLORS } from '../../../Constants'
import { Authcontext } from '../../../Context/Authcontext'
import Mydatepick from '../../../COmponents/Mydatepick'
import easyinvoice from 'easyinvoice';
import { Chart as ChartJS, ArcElement, Tooltip as Tooltip1, Legend } from 'chart.js';
import { saveAs } from "file-saver";

import { PencilIcon, TrashIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
    Spinner,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from 'react-redux'
import { fetchadvertiser } from '../../../Redux/Actions/Agency/advertiseragencyaction'
import { useNavigate, useNavigation, useParams } from 'react-router-dom'
import Mypopup from '../../../COmponents/Mypopup'
import gpeapi from '../../../apis/gpeapi'
import Mypaginate from '../../../COmponents/Mypaginate'
import { Empty, Spin } from 'antd'
import { Doughnut } from 'react-chartjs-2';
import { getStyle } from '@coreui/utils'
import { CChart } from '@coreui/react-chartjs'
import { ImCross } from 'react-icons/im'
import { FaCheckCircle } from 'react-icons/fa'



ChartJS.register(ArcElement, Tooltip1, Legend);


const excelJS = require("exceljs")

const Mainmarketing = () => {

    

    const token = localStorage.getItem('token')

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const { toggle } = useContext(Authcontext)
    const basicData = useSelector((state) => state.basicReducer)[0]
    const [platformurl, setplatformname] = useState()

    const navigate = useNavigate()

    const userinfo = JSON.parse(localStorage.getItem('userInfo'))

    const plandata = useSelector((state) => state.pricingReducer).filter((val) => val.id == userinfo.planid)[0]
    const TABLE_HEAD = ["ID", "Name", "Mobile", "Platform", "Ac Status", "PlnStatus", "PlnID", "PlnendDate", "PLAN INFO", "Camp run", "Today Camp run", "Action"];



    const [loading, setloading] = useState(false)
    const [statsdata, setstatdata] = useState([])
    const [monthrange, setmonthrange] = useState(null)
    const [fetchload, setfetchload] = useState(false)
    const [range, setrange] = useState(null)
    const [search, setsearch] = useState('')


    const handlefetchdata = () => {
        var date = new Date(range)
        var month = date.getMonth() + 1
        month = month < 10 ? '0' + month : month
        var year = date.getFullYear()
        const final = `${month}${year}`

        setsearch(final)
        callforAPI()
        // return final

    }



    const [downloading, setdownloading] = useState({
        id: '',
        loading: false
    })
    // useEffect(() => {
    //     platformurl && callforAPI()
    // }, [platformurl])

    const callforAPI = async () => {
        setloading(true)

        const res = await gpeapi.get(`/getmarketingdataadm?month=${range}`, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((ress) => {

            setstatdata(ress?.data)
        }).catch((e) => {
            toast.error(e?.response?.data?.msg)

        })
        setloading(false)

    }

    const genratedate1 = (date) => {
        const d = new Date(date);
        let month = months[d.getMonth()];
        let year = d.getFullYear()
        let datem = d.getDate()
        return `${datem} ${month} ${year}`
    }

    const onEditUser = async (val) => {

        setdownloading({
            id: val.id,
            loading: true
        })

        const callanydate1 = (days, date) => {
            var mysevendays = new Date(date);
            mysevendays.setDate(mysevendays.getDate() + days);
            return mysevendays.toISOString().split('T')[0]
        }

        var strmm = `${val?.txndata?.invoiceinfo?.company_name} ${val?.invoiceid} ${new Date()}`

        var invoicenamemm = strmm?.replace(/\s/g, "_")

        var data = {
            // Customize enables you to provide your own templates
            // Please review the documentation for instructions and examples
            "customize": {
                //  "template": fs.readFileSync('template.html', 'base64') // Must be base64 encoded html 
            },
            "images": {
                // The logo on top of your invoice
                "logo": basicData?.bigicon,
                // The invoice background
                // "background": "https://public.easyinvoice.cloud/img/watermark-draft.jpg"
            },
            // Your own data
            "sender": {
                "company": basicData?.company_name,
                "address": basicData?.address,
                "zip": basicData?.pincode,
                "city": basicData?.city,
                "country": basicData?.country,
                "custom1": `Email: ${basicData?.invoice_email}`,
                "custom2": `GST: ${basicData?.taxid}`,
                "custom3": `Service: SaaS (Software as a Service)`
            },
            // Your recipient
            "client": {
                "company": val?.txndata?.invoiceinfo?.company_name,
                "address": val?.txndata?.invoiceinfo?.address,
                "zip": val?.txndata?.invoiceinfo?.pincode,
                "city": val?.txndata?.invoiceinfo?.city,
                "country": val?.txndata?.invoiceinfo?.country,
                "custom1": `GST: ${val?.txndata?.invoiceinfo?.taxid}`,
                "custom2": `Partner ID - ${val?.userid}`,
                // "custom3": "custom value 3"
            },
            "information": {
                // Invoice number
                "number": val?.invoiceid,
                // Invoice data
                "date": callanydate1(0, val?.createdAt),
                // Invoice due date
                "due-date": callanydate1(0, val?.duedate)
            },
            // The products you would like to see on your invoice
            // Total values are being calculated automatically
            "products": [
                {
                    "quantity": 1,
                    "description": val?.txndata?.txnname,
                    "tax-rate": 18,
                    "price": val?.txndata?.amount
                },

            ],
            // The message you would like to display on the bottom of your invoice
            // "bottom-notice": "Kindly pay your invoice within 15 days.",
            "bottom-notice": "This is system generated print, No need to Sign",

            // Settings to customize your invoice
            "settings": {
                "currency": "INR", // See documentation 'Locales and Currency' for more info. Leave empty for no currency.
                // "locale": "nl-NL", // Defaults to en-US, used for number formatting (See documentation 'Locales and Currency')        
                // "margin-top": 25, // Defaults to '25'
                // "margin-right": 25, // Defaults to '25'
                // "margin-left": 25, // Defaults to '25'
                // "margin-bottom": 25, // Defaults to '25'
                // "format": "A4", // Defaults to A4, options: A3, A4, A5, Legal, Letter, Tabloid
                // "height": "1000px", // allowed units: mm, cm, in, px
                // "width": "500px", // allowed units: mm, cm, in, px
                // "orientation": "landscape", // portrait or landscape, defaults to portrait
            },
            // Translate your invoice to your preferred language
            "translate": {
                // "invoice": "FACTUUR",  // Default to 'INVOICE'
                "number": "Invoice No.", // Defaults to 'Number'
                "date": "Invoice Date", // Default to 'Date'
                // "due-date": "Verloopdatum", // Defaults to 'Due Date'
                // "subtotal": "Subtotaal", // Defaults to 'Subtotal'
                "products": "Monthly Subscription Plan", // Defaults to 'Products'
                // "quantity": "Aantal", // Default to 'Quantity'
                // "price": "Prijs", // Defaults to 'Price'
                // "product-total": "Totaal", // Defaults to 'Total'
                // "total": "Totaal", // Defaults to 'Total'
                "vat": "GST" // Defaults to 'vat'
            },
        };


        const invocedta = await easyinvoice.createInvoice(data, function (result) {
            easyinvoice.download(`${invoicenamemm}.pdf`, result.pdf)
        });

        setdownloading({
            id: val.id,
            loading: false
        })


    }


    const [loaddownxsl, setloaddownxsl] = useState(false)

    const handledownloadxsl = async () => {
        if (statsdata?.TotalUsers?.length) {

            const newarrdata = []

            const newdata = statsdata?.TotalUsers?.map((val)=>{
                let tempdata = {
                    amount:val?.amount,
                    igst:((val?.amount * 18) / 100).toFixed(2),
                    date:val?.date?.split('T')[0],
                    userid:val?.userid,
                    invoiceid:val?.invoiceid,
                    gst:val?.txndata?.invoiceinfo?.taxid,
                    company_name:val?.txndata?.invoiceinfo?.company_name,

                }

                newarrdata.push(tempdata)
            })

            const workbook = new excelJS.Workbook();  // Create a new workbook
            const worksheet = workbook.addWorksheet("My Users"); // New Worksheet
            workbook.creator = "test";
            workbook.lastModifiedBy = "test";
            workbook.created = new Date();
            workbook.modified = new Date();
            
            // Column for data in excel. key must match data key
            worksheet.columns = [
                { header: "SR.", key: "useridd", width: 10 },
                { header: "AGENCY ID", key: "userid", width: 10 },
                { header: "INVOICE NO", key: "invoiceid", width: 20 },
                { header: "DATE", key: "date", width: 15 },
                { header: "PARTY", key: "company_name", width: 25 },
                { header: "GST", key: "gst", width: 30 },
                { header: "TAXABLE", key: "amount", width: 10 },
                { header: "IGST", key: "igst", width: 10 },
                { header: "CGST", key: "", width: 10 },
                { header: "SGST", key: "", width: 10 },
            ];
            // Looping through User data
            let counter = 1;
            newarrdata.forEach(async (user) => {
                user.useridd = counter;
                worksheet.addRow(user); // Add data in worksheet
                counter++;
            });
            // Making first line in excel bold
            worksheet.getRow(1).eachCell((cell) => {
                cell.font = { bold: true };
            });

            workbook.xlsx.writeBuffer().then(function(buffer) {
                const blob = new Blob([buffer], { type: "applicationi/xlsx" });
                saveAs(blob, `GWALAX-${range}.xlsx`);
              });
          

        }

    }
    // console.log(statsdata)

   
    
    let graphdata1 = {labels: [`${statsdata?.data?.agency} - Free Trail`],
        datasets: [
          {
            label: '# of Signups',
            data: [statsdata?.data?.agency],
            backgroundColor: [
              '#114680',
            ],
          },
        ],
      }

     

      let graphdata2 = {labels: [`${statsdata?.data?.agency} - Free Trail`,`${statsdata?.data?.paid?.length} - Paid`],
        datasets: [
          {
            label: ['# of Paid'],
            data: [statsdata?.data?.agency,statsdata?.data?.paid?.length],
            backgroundColor: [
              '#114680',
              'green'
            ],
          },
        ],
      }

      let graphdata3 = {labels: [`${statsdata?.data?.agency} - Free Trail`,`${statsdata?.data?.active?.length} - Active`],
        datasets: [
          {
            label: '# of Active',
            data: [statsdata?.data?.agency,statsdata?.data?.active?.length],
            backgroundColor: [
              '#114680',
              '#9425b0'
            ],
          },
        ],
      }

      let graphdata4 = {labels: [`${statsdata?.data?.agency} - Free Trail`,`${statsdata?.data?.todayactive?.length} - Today Active`],
        datasets: [
          {
            label: '# of Today Active',
            data: [statsdata?.data?.agency,statsdata?.data?.todayactive?.length],
            backgroundColor: [
              '#114680',
              '#8f1d78'
            ],
          },
        ],
      }

      let graphdata5 = {labels: [`${statsdata?.data?.agency} - Free Trail`,`${statsdata?.TotalUsers?.filter((val)=>val?.planactive==true && val?.planid!==1)?.length} - Current Active Paid`],
        datasets: [
          {
            label: '# of Current Active Paid',
            data: [statsdata?.data?.agency,statsdata?.TotalUsers?.filter((val)=>val?.planactive==true && val?.planid!==1)?.length],
            backgroundColor: [
              '#114680',
              '#b2d65c'
            ],
          },
        ],
      }

  
      const getdatetimevia = (date) => {
        const mydate = new Date(date)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var second = mydate.getSeconds();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        second = second < 10 ? '0' + second : second;

        var strTime = hours + ':' + minutes + ':' + second + ' ' + ampm;

        return `${todate}-${month + 1}-${year} at ${strTime}`
    }

    const callanydate = (date) => {

        return date?.split('T')[0]
    }
    const diffrecedate = (enddate) => {

        var date1 = new Date(enddate)
        var date2 = new Date()

        var Difference_In_Time1 = date2.getTime() - date1.getTime();

        var Difference_In_Days = Math.abs(Difference_In_Time1 / (1000 * 3600 * 24)).toFixed();

        return Difference_In_Days


    }


    const getcamprunornot = (data,id)=>{
        let finddata = data?.filter((val)=>val==id)
        if(finddata?.length){
            return true
        }else{
           return false
        }
    }

    const onLoginas = (id) => {
        // navigate(`/${platformurl}/agency/viewcamp/${id}`)
        window.open(`/offerborn/amd/admin/agencylogin/${id}`)

    }

    return (
        <div style={{ backgroundColor: 'white' }} className={toggle ? "main active" : "main"}>
            {/* <Spinner className="h-10 w-10 text-blue-500/10" /> */}



            <ToastContainer />




            <div className='flex justify-between items-center m-2'>



                <p className='text-2xl font-medium'>MANAGE AGENCY MARKETING DATA</p>

                <div className='flex justify-center items-center'>
                    <Mydatepick value={monthrange} placeholder={'Select Month '} onChange={(e) => {

                        setmonthrange(e.target.value)
                        setrange(`${e.target.value}`)
                    }} />

                    <div>

                        <Button disabled={fetchload} className='ml-1 mr-1' onClick={handlefetchdata} size="sm">{fetchload ? <Spinner /> : 'Fetch'}</Button>
                    </div>

                    {/* {
                        statsdata?.TotalUsers?.length ?
                            <Button disabled={loaddownxsl} className='ml-1 mr-1' onClick={handledownloadxsl} size="sm">{loaddownxsl ? <Spinner /> : 'Download XSL'}</Button> : null
                    } */}


                </div>

            </div>


<Spin spinning={loading} >

            {
                statsdata?.TotalUsers?.length? <div className='p-5'>
                <div className='w-1/5  flex '>
                <Doughnut  data={graphdata1}/>
                <Doughnut   data={graphdata2} />
                <Doughnut  data={graphdata5} />
                <Doughnut  data={graphdata3} />
                <Doughnut  data={graphdata4} />
                    
                </div>
                 
            </div>:null}
            </Spin>


            <div className='p-5 pt-2'>

                <Spin spinning={loading}>

                    <CardBody className="overflow-scroll px-0">
                        <table className="w-full min-w-max table-auto text-left">
                            <thead>
                                <tr>
                                    {TABLE_HEAD.map((head) => (
                                        <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal leading-none opacity-70 uppercase"
                                            >
                                                {head}
                                            </Typography>
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {!statsdata?.TotalUsers?.length ? (
                                    <td colSpan={TABLE_HEAD.length}>

                                        <div className='flex justify-center text-center m-2'>
                                            <Empty />
                                        </div>
                                    </td>
                                ) : statsdata?.TotalUsers?.map(
                                    (val, index) => {
                                        const isLast = index === statsdata?.docs?.length - 1;
                                        const classes = isLast ? "p-4 w-2" : "p-4 w-2  border-b border-blue-gray-50";

                                        return (
                                            <tr key={index}>
                                            <td className={classes}>


                                                <Typography variant="small" color="blue-gray" className="font-bold">
                                                    {val?.id}
                                                </Typography>

                                            </td>
                                            <td className={classes}>
                                                <Typography  variant="small" color="blue" className="font-medium cursor-pointer ">
                                                    {val?.name}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer ">
                                                    {val?.mobile}
                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="blue-gray" className="font-medium text-sm ">
                                                    {val?.platformurl}
                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <div className="w-max">
                                                    <Chip
                                                        size="sm"
                                                        variant="ghost"
                                                        value={val?.ac_status}
                                                        color={
                                                            val?.ac_status == 'active' ? "green" : "red"
                                                        }
                                                    />

                                                </div>
                                            </td>

                                            <td className={classes}>
                                                <div className="w-max">
                                                    <Chip
                                                        size="sm"
                                                        variant="ghost"
                                                        value={val?.planactive ? 'TRUE' : 'FALSE'}
                                                        color={
                                                            val?.planactive ? "green" : "red"
                                                        }
                                                    />




                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Typography variant="small" color="blue" className="font-medium cursor-pointer text-center ">
                                                    {val?.planid}
                                                    <p className='text-gray-800 text-xs'>{val?.isfreetrail ? 'FREE' : ''}</p>

                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="black" className="font-normal ">
                                                    {callanydate(val?.planenddate)}

                                                    {
                                                        val?.lastlogin ? <p className='text-xs'>Lst Log: {getdatetimevia(val?.lastlogin)} </p>
                                                            : ''
                                                    }

                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="black" className="font-normal">
                                                {val?.extendplanended ? <span className='font-bold text-red-500'>Expired</span>:  val?.planended ? <span className='text-brown-800 font-bold '>{diffrecedate(val?.extendplanenddate)} Days Left For Over-Due</span> : <span className='text-green-400 font-bold '>{diffrecedate(val?.planenddate)} Days Left</span>}
                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="black" className="font-normal">
                                               {getcamprunornot(statsdata?.data?.active,val?.id)?<FaCheckCircle color='green' size={22} /> : <ImCross color='red' size={15}/>}
                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <Typography variant="small" color="black" className="font-normal">
                                               {getcamprunornot(statsdata?.data?.todayactive,val?.id)?<FaCheckCircle color='green' size={22} /> : <ImCross color='red' size={15}/>}
                                                </Typography>
                                            </td>


                                            <td className={classes}>
                                                            
                                                            <Tooltip content="Loginas Agency">
                                                                <IconButton onClick={() => onLoginas(val.platformurl)} variant="text" color="blue-gray">
                                                                    <ArrowRightIcon  className="h-6 w-6" />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </td>

                                            

                                          








                                        </tr>
                                        );
                                    },
                                )}
                            </tbody>
                        </table>
                    </CardBody>

                </Spin>



            </div>





        </div>
    )
}

export default Mainmarketing