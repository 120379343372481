
import './App.css';
import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";

import Page404Found from './COmponents/Page404Found';
import 'react-toastify/dist/ReactToastify.css';
import './globle.css'
import { useDispatch } from 'react-redux';
import { lazy, useEffect, useState } from 'react';
import { fethbasic } from './Redux/Actions/basicaction';
import { fetchpricing } from './Redux/Actions/pricingaction';
const Mainrouteadmin = lazy(() => import('./Admin/Routes/Mainrouteadmin'));
const Mainrouteweb = lazy(() => import('./Website/Routes/Mainrouteweb'));
const Mainrouteloginagencyadm = lazy(() => import('./Admin/Routes/Mainrouteloginagencyadm'));
const Mainroutepubloginagencyadm = lazy(() => import('./Admin/Routes/Mainroutepubloginagencyadm'));
const  Mainrouteadmemployee= lazy(() => import('./Admepmloyee/Routes/Mainrouteadmemployee'));






function App() {

  const [subdomain,setsubdomain] = useState(null)

  const dispatch = useDispatch()

  useEffect(()=>{
    callforfirst()
  },[])

  // useEffect(()=>{
  //   document.addEventListener('contextmenu', (e) => {
  //     e.preventDefault();
  //   });
  //   disableDevtool();
  // },[])

  const callforfirst = async ()=>{
   await dispatch(fethbasic())
    dispatch(fetchpricing())
  }

  // useEffect(()=>{
  //   const host = window.location.host
    
  //   const arr = host?.split('.').slice(0,host?.includes('localhost')?-1:-2)
  //   if(arr.length>0){
  //     if(arr[0]=='www'){
  //       setsubdomain(arr[1])
  //     }else{
  //       setsubdomain(arr[0])
  //     }
  //   } 

  // },[])




  return (


    // subdomain==null?(

      <Router>
      <Routes>
        {/* <MainRoutes/> */}
        {/* <Route path='/publisher' >
        </Route> */}

        {/* <Route path='/' element={<Mainrouteweb/>}/> */}
        <Route path='/*' element={<Mainrouteweb/>}/>
        {/* <Route path='/signup' element={<Signupforweb/>}/> */}


        <Route path='/offerborn/amd/admin/*' element={<Mainrouteadmin />} />
        <Route path='/offerborn/amd/employee/*' element={<Mainrouteadmemployee />} />

        <Route path='/offerborn/amd/admin/agencylogin/:platformurl*' element={<Mainrouteloginagencyadm />} />

        <Route path='/offerborn/amd/admin/agencylogin/:platformurl/loginaspub/:pubid*' element={<Mainroutepubloginagencyadm />} />

        {/* <Route path='/p/:platformurl/agency/*' element={<Mainrouteagency />} /> */}
        {/* <Route path='/p/:platformurl/agency/loginaspub/:pubid*' element={<Mainrouteloginpubagency />} /> */}

        {/* <Route path='/p/:platformurl/*' element={<Mainrouteagency />} /> */}

        {/* <Route path='/p/:platformurl/publisher/*' element={<MainRoutes />} /> */}

        {/* <Route path='/p/:platformurl/advertiser/*' element={<AdvMainroutes />} /> */}
        {/* <Route path='/p/:platformurl/employee/*' element={<Mainrouteemployee />} /> */}

        <Route path='*' element={<Page404Found/>}/>

        

      </Routes>
      {/* <Myfooter/> */}
      
    </Router>

    // ):(

    //   <div>{subdomain}</div>

    // )
   

    

    

  );
}

export default App;
