import React, { useEffect, useState } from 'react'
import { COLORS } from '../../../Constants'
import { useSelector } from 'react-redux'
import Mybtn from '../../../COmponents/Mybtn'
import Myinput from '../../../COmponents/Myinput'
import Myselect from '../../../COmponents/Myselect'
import { ToastContainer, toast } from 'react-toastify'
import gpeapi from '../../../apis/gpeapi'
import { Link, useNavigate } from 'react-router-dom'
import { FaCheckCircle } from "react-icons/fa";


const Signupforweb = () => {

    document.title = `Free Trial - OfferBorn`

    const navigate = useNavigate()
    const basic = useSelector((state) => state.basicReducer)[0]

    // console.log(basic?.send_otp)


    const countrydata = [
        {
            "label": "Afghanistan",
            "value": "Afghanistan"
        },
        {
            "label": "Åland Islands",
            "value": "Åland Islands"
        },
        {
            "label": "Albania",
            "value": "Albania"
        },
        {
            "label": "Algeria",
            "value": "Algeria"
        },
        {
            "label": "American Samoa",
            "value": "American Samoa"
        },
        {
            "label": "AndorrA",
            "value": "AndorrA"
        },
        {
            "label": "Angola",
            "value": "Angola"
        },
        {
            "label": "Anguilla",
            "value": "Anguilla"
        },
        {
            "label": "Antarctica",
            "value": "Antarctica"
        },
        {
            "label": "Antigua and Barbuda",
            "value": "Antigua and Barbuda"
        },
        {
            "label": "Argentina",
            "value": "Argentina"
        },
        {
            "label": "Armenia",
            "value": "Armenia"
        },
        {
            "label": "Aruba",
            "value": "Aruba"
        },
        {
            "label": "Australia",
            "value": "Australia"
        },
        {
            "label": "Austria",
            "value": "Austria"
        },
        {
            "label": "Azerbaijan",
            "value": "Azerbaijan"
        },
        {
            "label": "Bahamas",
            "value": "Bahamas"
        },
        {
            "label": "Bahrain",
            "value": "Bahrain"
        },
        {
            "label": "Bangladesh",
            "value": "Bangladesh"
        },
        {
            "label": "Barbados",
            "value": "Barbados"
        },
        {
            "label": "Belarus",
            "value": "Belarus"
        },
        {
            "label": "Belgium",
            "value": "Belgium"
        },
        {
            "label": "Belize",
            "value": "Belize"
        },
        {
            "label": "Benin",
            "value": "Benin"
        },
        {
            "label": "Bermuda",
            "value": "Bermuda"
        },
        {
            "label": "Bhutan",
            "value": "Bhutan"
        },
        {
            "label": "Bolivia",
            "value": "Bolivia"
        },
        {
            "label": "Bosnia and Herzegovina",
            "value": "Bosnia and Herzegovina"
        },
        {
            "label": "Botswana",
            "value": "Botswana"
        },
        {
            "label": "Bouvet Island",
            "value": "Bouvet Island"
        },
        {
            "label": "Brazil",
            "value": "Brazil"
        },
        {
            "label": "British Indian Ocean Territory",
            "value": "British Indian Ocean Territory"
        },
        {
            "label": "Brunei Darussalam",
            "value": "Brunei Darussalam"
        },
        {
            "label": "Bulgaria",
            "value": "Bulgaria"
        },
        {
            "label": "Burkina Faso",
            "value": "Burkina Faso"
        },
        {
            "label": "Burundi",
            "value": "Burundi"
        },
        {
            "label": "Cambodia",
            "value": "Cambodia"
        },
        {
            "label": "Cameroon",
            "value": "Cameroon"
        },
        {
            "label": "Canada",
            "value": "Canada"
        },
        {
            "label": "Cape Verde",
            "value": "Cape Verde"
        },
        {
            "label": "Cayman Islands",
            "value": "Cayman Islands"
        },
        {
            "label": "Central African Republic",
            "value": "Central African Republic"
        },
        {
            "label": "Chad",
            "value": "Chad"
        },
        {
            "label": "Chile",
            "value": "Chile"
        },
        {
            "label": "China",
            "value": "China"
        },
        {
            "label": "Christmas Island",
            "value": "Christmas Island"
        },
        {
            "label": "Cocos (Keeling) Islands",
            "value": "Cocos (Keeling) Islands"
        },
        {
            "label": "Colombia",
            "value": "Colombia"
        },
        {
            "label": "Comoros",
            "value": "Comoros"
        },
        {
            "label": "Congo",
            "value": "Congo"
        },
        {
            "label": "Congo, The Democratic Republic of the",
            "value": "Congo, The Democratic Republic of the"
        },
        {
            "label": "Cook Islands",
            "value": "Cook Islands"
        },
        {
            "label": "Costa Rica",
            "value": "Costa Rica"
        },
        {
            "label": "Cote D'Ivoire",
            "value": "Cote D'Ivoire"
        },
        {
            "label": "Croatia",
            "value": "Croatia"
        },
        {
            "label": "Cuba",
            "value": "Cuba"
        },
        {
            "label": "Cyprus",
            "value": "Cyprus"
        },
        {
            "label": "Czech Republic",
            "value": "Czech Republic"
        },
        {
            "label": "Denmark",
            "value": "Denmark"
        },
        {
            "label": "Djibouti",
            "value": "Djibouti"
        },
        {
            "label": "Dominica",
            "value": "Dominica"
        },
        {
            "label": "Dominican Republic",
            "value": "Dominican Republic"
        },
        {
            "label": "Ecuador",
            "value": "Ecuador"
        },
        {
            "label": "Egypt",
            "value": "Egypt"
        },
        {
            "label": "El Salvador",
            "value": "El Salvador"
        },
        {
            "label": "Equatorial Guinea",
            "value": "Equatorial Guinea"
        },
        {
            "label": "Eritrea",
            "value": "Eritrea"
        },
        {
            "label": "Estonia",
            "value": "Estonia"
        },
        {
            "label": "Ethiopia",
            "value": "Ethiopia"
        },
        {
            "label": "Falkland Islands (Malvinas)",
            "value": "Falkland Islands (Malvinas)"
        },
        {
            "label": "Faroe Islands",
            "value": "Faroe Islands"
        },
        {
            "label": "Fiji",
            "value": "Fiji"
        },
        {
            "label": "Finland",
            "value": "Finland"
        },
        {
            "label": "France",
            "value": "France"
        },
        {
            "label": "French Guiana",
            "value": "French Guiana"
        },
        {
            "label": "French Polynesia",
            "value": "French Polynesia"
        },
        {
            "label": "French Southern Territories",
            "value": "French Southern Territories"
        },
        {
            "label": "Gabon",
            "value": "Gabon"
        },
        {
            "label": "Gambia",
            "value": "Gambia"
        },
        {
            "label": "Georgia",
            "value": "Georgia"
        },
        {
            "label": "Germany",
            "value": "Germany"
        },
        {
            "label": "Ghana",
            "value": "Ghana"
        },
        {
            "label": "Gibraltar",
            "value": "Gibraltar"
        },
        {
            "label": "Greece",
            "value": "Greece"
        },
        {
            "label": "Greenland",
            "value": "Greenland"
        },
        {
            "label": "Grenada",
            "value": "Grenada"
        },
        {
            "label": "Guadeloupe",
            "value": "Guadeloupe"
        },
        {
            "label": "Guam",
            "value": "Guam"
        },
        {
            "label": "Guatemala",
            "value": "Guatemala"
        },
        {
            "label": "Guernsey",
            "value": "Guernsey"
        },
        {
            "label": "Guinea",
            "value": "Guinea"
        },
        {
            "label": "Guinea-Bissau",
            "value": "Guinea-Bissau"
        },
        {
            "label": "Guyana",
            "value": "Guyana"
        },
        {
            "label": "Haiti",
            "value": "Haiti"
        },
        {
            "label": "Heard Island and Mcdonald Islands",
            "value": "Heard Island and Mcdonald Islands"
        },
        {
            "label": "Holy See (Vatican City State)",
            "value": "Holy See (Vatican City State)"
        },
        {
            "label": "Honduras",
            "value": "Honduras"
        },
        {
            "label": "Hong Kong",
            "value": "Hong Kong"
        },
        {
            "label": "Hungary",
            "value": "Hungary"
        },
        {
            "label": "Iceland",
            "value": "Iceland"
        },
        {
            "label": "India",
            "value": "India"
        },
        {
            "label": "Indonesia",
            "value": "Indonesia"
        },
        {
            "label": "Iran, Islamic Republic Of",
            "value": "Iran, Islamic Republic Of"
        },
        {
            "label": "Iraq",
            "value": "Iraq"
        },
        {
            "label": "Ireland",
            "value": "Ireland"
        },
        {
            "label": "Isle of Man",
            "value": "Isle of Man"
        },
        {
            "label": "Israel",
            "value": "Israel"
        },
        {
            "label": "Italy",
            "value": "Italy"
        },
        {
            "label": "Jamaica",
            "value": "Jamaica"
        },
        {
            "label": "Japan",
            "value": "Japan"
        },
        {
            "label": "Jersey",
            "value": "Jersey"
        },
        {
            "label": "Jordan",
            "value": "Jordan"
        },
        {
            "label": "Kazakhstan",
            "value": "Kazakhstan"
        },
        {
            "label": "Kenya",
            "value": "Kenya"
        },
        {
            "label": "Kiribati",
            "value": "Kiribati"
        },
        {
            "label": "Korea, Democratic People'S Republic of",
            "value": "Korea, Democratic People'S Republic of"
        },
        {
            "label": "Korea, Republic of",
            "value": "Korea, Republic of"
        },
        {
            "label": "Kuwait",
            "value": "Kuwait"
        },
        {
            "label": "Kyrgyzstan",
            "value": "Kyrgyzstan"
        },
        {
            "label": "Lao People'S Democratic Republic",
            "value": "Lao People'S Democratic Republic"
        },
        {
            "label": "Latvia",
            "value": "Latvia"
        },
        {
            "label": "Lebanon",
            "value": "Lebanon"
        },
        {
            "label": "Lesotho",
            "value": "Lesotho"
        },
        {
            "label": "Liberia",
            "value": "Liberia"
        },
        {
            "label": "Libyan Arab Jamahiriya",
            "value": "Libyan Arab Jamahiriya"
        },
        {
            "label": "Liechtenstein",
            "value": "Liechtenstein"
        },
        {
            "label": "Lithuania",
            "value": "Lithuania"
        },
        {
            "label": "Luxembourg",
            "value": "Luxembourg"
        },
        {
            "label": "Macao",
            "value": "Macao"
        },
        {
            "label": "Macedonia, The Former Yugoslav Republic of",
            "value": "Macedonia, The Former Yugoslav Republic of"
        },
        {
            "label": "Madagascar",
            "value": "Madagascar"
        },
        {
            "label": "Malawi",
            "value": "Malawi"
        },
        {
            "label": "Malaysia",
            "value": "Malaysia"
        },
        {
            "label": "Maldives",
            "value": "Maldives"
        },
        {
            "label": "Mali",
            "value": "Mali"
        },
        {
            "label": "Malta",
            "value": "Malta"
        },
        {
            "label": "Marshall Islands",
            "value": "Marshall Islands"
        },
        {
            "label": "Martinique",
            "value": "Martinique"
        },
        {
            "label": "Mauritania",
            "value": "Mauritania"
        },
        {
            "label": "Mauritius",
            "value": "Mauritius"
        },
        {
            "label": "Mayotte",
            "value": "Mayotte"
        },
        {
            "label": "Mexico",
            "value": "Mexico"
        },
        {
            "label": "Micronesia, Federated States of",
            "value": "Micronesia, Federated States of"
        },
        {
            "label": "Moldova, Republic of",
            "value": "Moldova, Republic of"
        },
        {
            "label": "Monaco",
            "value": "Monaco"
        },
        {
            "label": "Mongolia",
            "value": "Mongolia"
        },
        {
            "label": "Montserrat",
            "value": "Montserrat"
        },
        {
            "label": "Morocco",
            "value": "Morocco"
        },
        {
            "label": "Mozambique",
            "value": "Mozambique"
        },
        {
            "label": "Myanmar",
            "value": "Myanmar"
        },
        {
            "label": "Namibia",
            "value": "Namibia"
        },
        {
            "label": "Nauru",
            "value": "Nauru"
        },
        {
            "label": "Nepal",
            "value": "Nepal"
        },
        {
            "label": "Netherlands",
            "value": "Netherlands"
        },
        {
            "label": "Netherlands Antilles",
            "value": "Netherlands Antilles"
        },
        {
            "label": "New Caledonia",
            "value": "New Caledonia"
        },
        {
            "label": "New Zealand",
            "value": "New Zealand"
        },
        {
            "label": "Nicaragua",
            "value": "Nicaragua"
        },
        {
            "label": "Niger",
            "value": "Niger"
        },
        {
            "label": "Nigeria",
            "value": "Nigeria"
        },
        {
            "label": "Niue",
            "value": "Niue"
        },
        {
            "label": "Norfolk Island",
            "value": "Norfolk Island"
        },
        {
            "label": "Northern Mariana Islands",
            "value": "Northern Mariana Islands"
        },
        {
            "label": "Norway",
            "value": "Norway"
        },
        {
            "label": "Oman",
            "value": "Oman"
        },
        {
            "label": "Pakistan",
            "value": "Pakistan"
        },
        {
            "label": "Palau",
            "value": "Palau"
        },
        {
            "label": "Palestinian Territory, Occupied",
            "value": "Palestinian Territory, Occupied"
        },
        {
            "label": "Panama",
            "value": "Panama"
        },
        {
            "label": "Papua New Guinea",
            "value": "Papua New Guinea"
        },
        {
            "label": "Paraguay",
            "value": "Paraguay"
        },
        {
            "label": "Peru",
            "value": "Peru"
        },
        {
            "label": "Philippines",
            "value": "Philippines"
        },
        {
            "label": "Pitcairn",
            "value": "Pitcairn"
        },
        {
            "label": "Poland",
            "value": "Poland"
        },
        {
            "label": "Portugal",
            "value": "Portugal"
        },
        {
            "label": "Puerto Rico",
            "value": "Puerto Rico"
        },
        {
            "label": "Qatar",
            "value": "Qatar"
        },
        {
            "label": "Reunion",
            "value": "Reunion"
        },
        {
            "label": "Romania",
            "value": "Romania"
        },
        {
            "label": "Russian Federation",
            "value": "Russian Federation"
        },
        {
            "label": "RWANDA",
            "value": "RWANDA"
        },
        {
            "label": "Saint Helena",
            "value": "Saint Helena"
        },
        {
            "label": "Saint Kitts and Nevis",
            "value": "Saint Kitts and Nevis"
        },
        {
            "label": "Saint Lucia",
            "value": "Saint Lucia"
        },
        {
            "label": "Saint Pierre and Miquelon",
            "value": "Saint Pierre and Miquelon"
        },
        {
            "label": "Saint Vincent and the Grenadines",
            "value": "Saint Vincent and the Grenadines"
        },
        {
            "label": "Samoa",
            "value": "Samoa"
        },
        {
            "label": "San Marino",
            "value": "San Marino"
        },
        {
            "label": "Sao Tome and Principe",
            "value": "Sao Tome and Principe"
        },
        {
            "label": "Saudi Arabia",
            "value": "Saudi Arabia"
        },
        {
            "label": "Senegal",
            "value": "Senegal"
        },
        {
            "label": "Serbia and Montenegro",
            "value": "Serbia and Montenegro"
        },
        {
            "label": "Seychelles",
            "value": "Seychelles"
        },
        {
            "label": "Sierra Leone",
            "value": "Sierra Leone"
        },
        {
            "label": "Singapore",
            "value": "Singapore"
        },
        {
            "label": "Slovakia",
            "value": "Slovakia"
        },
        {
            "label": "Slovenia",
            "value": "Slovenia"
        },
        {
            "label": "Solomon Islands",
            "value": "Solomon Islands"
        },
        {
            "label": "Somalia",
            "value": "Somalia"
        },
        {
            "label": "South Africa",
            "value": "South Africa"
        },
        {
            "label": "South Georgia and the South Sandwich Islands",
            "value": "South Georgia and the South Sandwich Islands"
        },
        {
            "label": "Spain",
            "value": "Spain"
        },
        {
            "label": "Sri Lanka",
            "value": "Sri Lanka"
        },
        {
            "label": "Sudan",
            "value": "Sudan"
        },
        {
            "label": "Suriname",
            "value": "Suriname"
        },
        {
            "label": "Svalbard and Jan Mayen",
            "value": "Svalbard and Jan Mayen"
        },
        {
            "label": "Swaziland",
            "value": "Swaziland"
        },
        {
            "label": "Sweden",
            "value": "Sweden"
        },
        {
            "label": "Switzerland",
            "value": "Switzerland"
        },
        {
            "label": "Syrian Arab Republic",
            "value": "Syrian Arab Republic"
        },
        {
            "label": "Taiwan, Province of China",
            "value": "Taiwan, Province of China"
        },
        {
            "label": "Tajikistan",
            "value": "Tajikistan"
        },
        {
            "label": "Tanzania, United Republic of",
            "value": "Tanzania, United Republic of"
        },
        {
            "label": "Thailand",
            "value": "Thailand"
        },
        {
            "label": "Timor-Leste",
            "value": "Timor-Leste"
        },
        {
            "label": "Togo",
            "value": "Togo"
        },
        {
            "label": "Tokelau",
            "value": "Tokelau"
        },
        {
            "label": "Tonga",
            "value": "Tonga"
        },
        {
            "label": "Trinidad and Tobago",
            "value": "Trinidad and Tobago"
        },
        {
            "label": "Tunisia",
            "value": "Tunisia"
        },
        {
            "label": "Turkey",
            "value": "Turkey"
        },
        {
            "label": "Turkmenistan",
            "value": "Turkmenistan"
        },
        {
            "label": "Turks and Caicos Islands",
            "value": "Turks and Caicos Islands"
        },
        {
            "label": "Tuvalu",
            "value": "Tuvalu"
        },
        {
            "label": "Uganda",
            "value": "Uganda"
        },
        {
            "label": "Ukraine",
            "value": "Ukraine"
        },
        {
            "label": "United Arab Emirates",
            "value": "United Arab Emirates"
        },
        {
            "label": "United Kingdom",
            "value": "United Kingdom"
        },
        {
            "label": "United States",
            "value": "United States"
        },
        {
            "label": "United States Minor Outlying Islands",
            "value": "United States Minor Outlying Islands"
        },
        {
            "label": "Uruguay",
            "value": "Uruguay"
        },
        {
            "label": "Uzbekistan",
            "value": "Uzbekistan"
        },
        {
            "label": "Vanuatu",
            "value": "Vanuatu"
        },
        {
            "label": "Venezuela",
            "value": "Venezuela"
        },
        {
            "label": "Viet Nam",
            "value": "Viet Nam"
        },
        {
            "label": "Virgin Islands, British",
            "value": "Virgin Islands, British"
        },
        {
            "label": "Virgin Islands, U.S.",
            "value": "Virgin Islands, U.S."
        },
        {
            "label": "Wallis and Futuna",
            "value": "Wallis and Futuna"
        },
        {
            "label": "Western Sahara",
            "value": "Western Sahara"
        },
        {
            "label": "Yemen",
            "value": "Yemen"
        },
        {
            "label": "Zambia",
            "value": "Zambia"
        },
        {
            "label": "Zimbabwe",
            "value": "Zimbabwe"
        }
    ]



    const pricing = useSelector((state) => state.pricingReducer)[0]

    const [loading, setloading] = useState(false)
    const [mydta, setmydta] = useState({
        name: '',
        mobile: '',
        country_code: '+91',
        email: '',
        password: '',
        companyname: '',
        platformurl: '',
        websiteurl: '',
        skype: '',
        country: '',
        ismobileverified: false
    })


    const [countrycode, setcountrycode] = useState([{
        "name": "India",
        "dial_code": "+91",
        "emoji": "🇮🇳",
        "code": "IN"
    }])

    useEffect(() => {
        callforapi()
    }, [])

    const callforapi = async () => {
        const resee = await gpeapi.get(`/getcountrycode`).then((ress) => {
            setcountrycode(ress.data?.data)
            // console.log('from add adveve', ress.data?.user)
        }).catch((e) => toast.error(e?.response.data.msg))
    }


    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value
        setmydta({ ...mydta, [name]: value })
    }

    const callforremovespaceplatform = (e) => {
        const name = e.target.name
        const value = e.target.value

        const updatee = value?.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')
        setmydta({ ...mydta, [name]: updatee })
    }

    const handlelogin = async () => {
        if (mydta.name !== '') {
            if (mydta.email !== '') {
                if (mydta.password !== '') {
                    if (mydta.mobile !== '') {

                        if (mydta?.country_code === '+91' ? mydta.ismobileverified !== false : true) {

                            if (mydta.companyname !== '') {
                                if (mydta.websiteurl !== '') {

                                    if (mydta.country !== '') {

                                        if (mydta.platformurl !== '') {
                                            await setloading(true)
                                            await gpeapi.post(`/agencysignup`, mydta).then((res) => {
                                                if (res?.data.success) {
                                                    toast.success(res?.data.msg)
                                                    toast.success(`Your Platform Login URL is - https://${mydta.platformurl}.offerborn.com`)

                                                    setTimeout(() => {
                                                        navigate('/thankyou')
                                                    }, 3000);


                                                }
                                            }).catch((e) => {

                                                toast.error(e?.response?.data.msg)

                                            })
                                            setloading(false)



                                        } else {
                                            toast.warn('Please Enter Platform Name')
                                        }
                                    } else {
                                        toast.warn('Please Select Country')
                                    }

                                } else {
                                    toast.warn('Please Enter Website URL')
                                }

                            } else {
                                toast.warn('Please Enter Company Name')
                            }

                        } else {
                            toast.warn('Please Verify Mobile')
                        }

                    } else {
                        toast.warn('Please Enter Mobile Number')
                    }

                } else {
                    toast.warn('Please Enter Password')
                }

            } else {
                toast.warn('Please Enter Email ID')
            }

        } else {
            toast.warn('Please Enter Full Name')
        }

    }

    function phonenumber(inputtxt) {

        const pattern = /^\d{10}$/
        const isValid = pattern.test(inputtxt)

        return isValid;
    }


    const [otpsent, setotpsent] = useState(false)
    const [otp, setotp] = useState(null)
    const [otpsentloading, setotpsentloading] = useState(false)
    const [otpverifyloading, setotpverifyloading] = useState(false)
    const [isotpverifyed, setisotpverifyed] = useState(false)

    const handlesendotp = async () => {
        setotpsentloading(true)
        if (basic?.send_otp) {
            if (phonenumber(mydta?.mobile)) {
                await gpeapi.post(`/sendotpmobile`, { mobile: mydta?.mobile }).then((res) => {
                    if (res?.data.success) {
                        setotpsent(true)
                        toast.success(res?.data.msg)
                    }
                }).catch((e) => {
                    toast.error(e?.response?.data.msg)
                })

            } else {
                toast.warning('Please enter a Valid Mobile Number')
            }
        } else {
            setotpsent(true)
            setisotpverifyed(true)
            setmydta({ ...mydta, ismobileverified: true })
            toast.success('Mobile Number Verified')


        }

        setotpsentloading(false)
    }


    const handleotpverify = async () => {
        setotpverifyloading(true)
        await gpeapi.post(`/verifyotpmobile`, { mobile: mydta?.mobile, otp: otp }).then((res) => {
            if (res?.data.success) {
                setisotpverifyed(true)
                setmydta({ ...mydta, ismobileverified: true })
                toast.success(res?.data.msg)
            }
        }).catch((e) => {
            toast.error(e?.response?.data.msg)
        })

        setotpverifyloading(false)
    }

    // console.log(mydta)


    return (
        <div style={{ marginTop: 50 }}>

            <ToastContainer />
            <section class="section service" id="service" aria-label="service">
                <div class="container">

                    <h2 class="h3 section-title" style={{ color: COLORS.primary, fontWeight: 'bold' }}>Start 15 days free trial</h2>

                    <p class="section-text">
                        No credit card required. Complete access. Full support. No hidden fees
                    </p>


                    <div className=' flex justify-center'>


                        <div className="w-full bg-white rounded-lg  shadow-2xl dark:border md:mt-0 sm:max-w-md xl:p-3 dark:bg-gray-800 dark:border-gray-700 flex justify-center flex-col">

                            {/* <h3>Create an Account</h3> */}

                            <div className='m-3'>
                                <p className='text-3xl text-center text-black font-medium'>Create an Account</p>
                                <p className='text-sm text-center mt-2 text-gray-400 '>Get started with your free account today. No credit card needed and no setup fees.</p>

                            </div>



                            <div className="p-8 space-y-4 md:space-y-6 sm:p-8">

                                <div className="space-y-4 md:space-y-6" action="#">

                                    <Myinput width={'100%'} placeholder={'Full Name'} name='name' value={mydta.name} onChange={handleChnage} />

                                    <Myinput width={'100%'} placeholder={'Email'} name='email' value={mydta.email} onChange={handleChnage} />

                                    <Myinput type={'password'} width={'100%'} placeholder={'Password'} name='password' value={mydta.password} onChange={handleChnage} />



                                    <div style={{ justifyContent: 'center', alignItems: 'center' }}>

                                        <div className='flex justify-center items-center w-full'>
                                            <select disabled={otpsent} value={mydta.country_code} onChange={(e) => setmydta({ ...mydta, country_code: e.target.value })} className='rounded-md text-sm border-gray-400 '>
                                                {
                                                    countrycode?.map((val) => (
                                                        <option value={val?.dial_code}>{val?.emoji}{val?.dial_code}</option>

                                                    ))
                                                }
                                            </select>

                                            <Myinput disabled={otpsent} classname={'px-2'} max={10} width={'100%'} name={'mobile'} type={'text'} value={mydta.mobile} onChange={handleChnage} placeholder={'Enter Mobile Number'} />

                                            {
                                                isotpverifyed && <p><FaCheckCircle color='green' size={25} /></p>
                                            }
                                        </div>

                                        {
                                            mydta?.country_code == '+91' && mydta?.mobile?.length == 10 &&

                                            <div>

                                                {
                                                    otpsent && !isotpverifyed &&
                                                    <Myinput classname={'px-2'} max={6} width={'100%'} name={'mobile'} type={'text'} value={otp} onChange={(e) => setotp(e.target.value)} placeholder={'Enter OTP'} />
                                                }

                                                {
                                                    !otpsent &&
                                                    <Mybtn loading={otpsentloading} onClick={handlesendotp} radius={5} title={'SEND OTP'} width={'100%'} />
                                                }

                                                {
                                                    otpsent && !isotpverifyed &&
                                                    <Mybtn loading={otpverifyloading} onClick={handleotpverify} radius={5} title={'VERIFY OTP'} width={'100%'} />
                                                }
                                            </div>
                                        }





                                    </div>



                                    <Myselect width={'100%'} data={countrydata} placeholder={'Country'} value={mydta.country} onChange={(e) => setmydta({ ...mydta, country: e })} />

                                    <Myinput width={'100%'} placeholder={'Skype '} name='skype' value={mydta.skype} onChange={handleChnage} />

                                    <Myinput width={'100%'} placeholder={'Company Name'} name='companyname' value={mydta.companyname} onChange={handleChnage} />

                                    <Myinput width={'100%'} placeholder={'Company Website'} name='websiteurl' value={mydta.websiteurl} onChange={handleChnage} />

                                    <Myinput tips={`Will be used to create ${mydta.platformurl === '' ? 'YOURPLATFORMNAME.offerborn.com' : `${mydta.platformurl}.offerborn.com`} sub domain`} width={'100%'} placeholder={'Platform Name'} name='platformurl' value={mydta.platformurl} onChange={(e) => {
                                        // handleChnage(e)
                                        callforremovespaceplatform(e)
                                    }} />
                                    {
                                        mydta?.platformurl?.length ? <p className='text-center'><span className='text-center bg-cyan-900 px-4 py-1 rounded-md text-white font-semibold'>{mydta.platformurl}.offerborn.com</span></p> : null
                                    }



                                    <div className=' flex-col justify-center items-center'>

                                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                            By submitting this form you accept <a href="/terms-&-conditions" className="text-blue-600 font-medium text-primary-600 hover:underline dark:text-primary-500">Terms and Conditions</a> and <a href="/privacy-policy" className="text-blue-600 font-medium text-primary-600 hover:underline dark:text-primary-500">Privacy Policy</a>
                                        </p>

                                    </div>




                                    <div className='flex justify-end'>
                                        <Mybtn loading={loading} onClick={handlelogin} title={'Signup'} />
                                    </div>



                                </div>
                            </div>
                        </div>






                    </div>

                </div>
            </section>
        </div>
    )
}

export default Signupforweb